.auth-mode {
    display: flex;
    justify-content: space-between;
    padding-top: 0%;
}

    .auth-mode button {
        width: 50%;
        border: none;
        background-color: #ddd;
        border-bottom: 1px solid black;
        padding: 2px 0px 3px;
    }

        .auth-mode button:first-child {
            border-right: 1px solid black;
        }

        .auth-mode button:last-child {
            border-left: 1px solid black;
        }

        .auth-mode button.selected {
            background-color: unset;
            border: none;
        }

.auth-form {
    margin: 8vh 15px 0px 15px;
    border-radius: 3px;
    border: 2px solid black;
    border-radius: 5px;
}

    .auth-form .auth-sub-btn {
        width: 200px;
        margin: auto;
    }

    .auth-form .auth-sub-btn-wrapper {
        display: flex;
        justify-content: center;
    }

    .auth-form form {
        padding: 5px;
        margin: 5px;
    }

        .auth-form form div {
            margin-top: 5px;
        }

    .auth-form input.error {
        border-color: red;
    }

    .auth-form label.error {
        color: red;
        height: 1.25em;
        display: block;
    }



.auth-fields label {
    display: block;
}

.auth-fields input {
    width: 100%;
    box-sizing: border-box;
}
