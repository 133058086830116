.header {
    display: flex;
    height: 4vh;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
}

    .header svg {
        height: 3vh;
        cursor: pointer;
    }

    .header .left, .header .right {
        width: 100px;
    }

    .header .right {
        display: flex;
        justify-content: flex-end;
    }

    .header * * {
        margin-left: 3px;
        margin-right: 3px;
    }
