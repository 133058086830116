.card {
    height: 14em;
    margin: 5px 0 15px 0;
    padding: 10px;
    border: 1px solid darkgray;
    border-radius: 10px;
}

.word {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
    text-align: center;
    height: 50%;
}

.word-hidden {
    filter: blur(0.2em);
    cursor: pointer;
}

    .word-hidden:hover {
        background: #f0f0f0;
    }

.mid-split {
    position: relative;
    top: -50%;
    transform: translateY(-50%);
    margin: 0px;
    padding: 0px;
}

.flipper {
    position: relative;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    font-size: 0.8em;
    border: 1px solid black;
    border-radius: 6px;
    cursor: pointer;
}

    .flipper:hover {
        background: #dde;
    }

.buttons-row {
    display: flex;
    justify-content: center;
}

.svg-button {
    display: flex;
    justify-content: center;
    align-items: center;
    fill: black;
    cursor: pointer;
}

    .svg-button svg {
        height: 90%;
    }

.btn-prior {
    height: 36px;
    width: 36px;
    fill: none;
    stroke-width: 0.5px;
}

    .btn-prior.pressed {
        border-radius: 5px;
        border-width: 3px;
    }

.btn-down {
    stroke: red;
}

    .btn-down.pressed {
        fill: red;
        background-color: pink;
        border-color: red;
    }

.btn-up {
    stroke: green;
}

    .btn-up.pressed {
        fill: green;
        background-color: darkseagreen;
        border-color: green;
    }

.btn-next {
    margin: 0 10px 0 10px;
    max-width: 161px;
    width: 100%;
    height: 36px;
}

.placeholder-loading {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2em;
}

#idk42 {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

    #idk42 span {
        font-size: 0.75em;
        line-height: 1em;
    }

    #idk42 button {
        width: 1.3em;
        height: 1.3em;
        padding: 1px;
        border-radius: 5px;
        fill: #5f5f5f;
        border-width: 0.75px;
    }

@media screen and (max-aspect-ratio: 1/1) {
    .buttons-row{
        position: absolute;
        bottom: 3em;
        width: 100%;
    }
}
