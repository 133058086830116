html, body {
    height: 100%;
    max-height: 100%;
    line-height: 1.5em;
    min-width: 300px;
    font-size: 20px;
}

#root {
    height: 100%;
    background-color: #f4f4f4;
}

/*desktop*/
@media screen and (min-aspect-ratio: 1/1) {
    #root {
        margin-right: auto;
        margin-left: auto;
        max-width: 600px;
        filter: unset;
    }

    body {
        /*original flag colors*/
        /*background: linear-gradient(to bottom, #007DC3 30%, black 36% 69%, white 75%);*/
        /*low saturated colors*/
        background: linear-gradient(to bottom, #81d2ff 30%, #999999 36% 69%, #ffffff 75%);
    }
}

main{
    padding: 12px;
}
