.exercise-menu {
    padding-top: 25px;
    text-align: center;
}

    .exercise-menu button {
        margin-left: auto;
        width: 60%;
        min-width: 200px;
        margin: 10px 0;
    }
